const MarketplacePage = () => {
  return (
    <div>
      <div className="container text-center">
        <h1>Marketplace Page</h1>
      </div>
    </div>
  );
}

export default MarketplacePage;
