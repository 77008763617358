// Ordered by frequency
export const CATEGORY_LIST = [
  "Card Game",
  "Expansion for Base-game",
  "Wargame",
  "Fantasy",
  "Childrens Game",
  "Miniatures",
  "Dice",
  "Party Game",
  "Science Fiction",
  "Fighting",
  "Abstract Strategy",
  "Educational",
  "Economic",
  "Print & Play",
  "Animals",
  "Movies / TV / Radio theme",
  "Trivia",
  "Adventure",
  "Humor",
  "World War II",
  "Action / Dexterity",
  "Sports",
  "Deduction",
  "Medieval",
  "Racing",
  "Bluffing",
  "Exploration",
  "Book",
  "Horror",
  "Word Game",
  "Negotiation",
  "Puzzle",
  "Memory",
  "Nautical",
  "Ancient",
  "Real-time",
  "Novel-based",
  "Collectible Components",
  "Political",
  "Comic Book / Strip",
  "Travel",
  "Territory Building",
  "Modern Warfare",
  "Aviation / Flight",
  "City Building",
  "Murder/Mystery",
  "Mythology",
  "Math",
  "Transportation",
  "Video Game Theme",
  "Mature / Adult",
  "Space Exploration",
  "Trains",
  "Environmental",
  "Pirates",
  "Civilization",
  "Number",
  "Zombies",
  "Napoleonic",
  "American West",
  "Industry / Manufacturing",
  "Religious",
  "Farming",
  "World War I",
  "Electronic",
  "Post-Napoleonic",
  "Spies/Secret Agents",
  "Renaissance",
  "Music",
  "American Civil War",
  "Maze",
  "Prehistoric",
  "Age of Reason",
  "Civil War",
  "Fan Expansion",
  "Pike and Shot",
  "Mafia",
  "Medical",
  "American Revolutionary War",
  "Vietnam War",
  "Game System",
  "American Indian Wars",
  "Arabian",
  "Korean War",
];

// Ordered by frequency, not every mechanic chosen the one above 1000
export const MECHANIC_LIST = [
  "Dice Rolling",
  "Hand Management",
  "Roll / Spin and Move",
  "Set Collection",
  "Variable Player Powers",
  "Cooperative Game",
  "Open Drafting",
  "Hexagon Grid",
  "Simulation",
  "Modular Board",
  "Tile Placement",
  "Grid Movement",
  "Action Points",
  "Area Majority / Influence",
  "Simultaneous Action Selection",
  "Memory",
  "Point to Point Movement",
  "Solo / Solitaire Game",
  "Take That",
  "Area Movement",
  "Player Elimination",
  "Auction/Bidding",
  "Team-Based Game",
  "Deck, Bag, and Pool Building",
  "Push Your Luck",
  "Trading",
  "Pattern Building",
  "Role Playing",
  "Scenario / Mission / Campaign Game",
  "Paper-and-Pencil",
  "Storytelling",
  "Worker Placement",
  "Pattern Recognition",
  "Campaign / Battle Card Driven",
  "Betting and Bluffing",
  "Pick-up and Deliver",
  "Trick-taking",
  "Voting",
  "Network and Route Building",
  "Action Queue",
  "Race",
  "Secret Unit Deployment",
  "Variable Set-up",
  "Acting",
  "Measurement Movement",
  "Deduction",
  "Events",
  "Square Grid",
  "Variable Phase Order",
  "Movement Points",
  "Stock Holding",
  "Card Play Conflict Resolution",
  "End Game Bonuses",
  "Line of Sight",
  "Real-Time",
  "Deck Construction",
  "Rock-Paper-Scissors",
  "Track Movement",
  "Chit-Pull System",
];
