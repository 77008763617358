// Top 3 from search.js
export const TOP_CATEGORY_LIST = [
  "Card Game",
  "Wargame",
  "Fantasy",
];

// Top 3 from search.js
export const TOP_MECHANIC_LIST = [
  "Dice Rolling",
  "Hand Management",
  "Roll / Spin and Move",
];
